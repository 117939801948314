import { Grid } from '@devstart/react-bootstrap';
import { graphql, withPrefix } from 'gatsby';
import type { TFunction } from 'i18next';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

// Local Utilities
import LearnLayout from '../../../components/layouts/learn';
import {
  AllChallengeNode,
  Challenge,
  ChallengeMeta,
  ChallengeNode,
  ChangeTourStatusProps,
  User
} from '../../../redux/prop-types';
import ChallengeDescription from '../components/challenge-description';
import ChallengeTitle from '../components/challenge-title';
import CompletionModal from '../components/completion-modal';
import Hotkeys from '../components/hotkeys';
import {
  challengeMounted,
  openModal,
  submitChallenge,
  updateChallengeMeta,
  isAllowedChallenge
} from '../redux/actions';
import {
  completedChallengesIdsSelector,
  isChallengeCompletedSelector,
  isCompletedChallengesLoading
} from '../redux/selectors';

// Styles
import {
  createFlashMessage,
  removeFlashMessage
} from '../../../components/Flash/redux';
import {
  isSignedInSelector,
  signInLoadingSelector,
  userRegistrationStatusSelector,
  userSelector
} from '../../../redux/selectors';
import { hardGoTo as navigate, changeTourStatus } from '../../../redux/actions';
import BreadCrumb from '../components/bread-crumb';
import '../video.css';
import { RegistrationResponseProps } from '../../../utils/ajax';
import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';
import ChallengeNotAllowedModal from '../../../components/modals/challenge-not-allowed-modal';

// Redux Setup
const mapStateToProps = createSelector(
  isChallengeCompletedSelector,
  isSignedInSelector,
  signInLoadingSelector,
  completedChallengesIdsSelector,
  userSelector,
  userRegistrationStatusSelector,
  isCompletedChallengesLoading,
  (
    isChallengeCompleted: boolean,
    isSignedIn: boolean,
    signInLoading: boolean,
    completedChallengeIds: string[],
    user: User,
    userRegistrationStatus: RegistrationResponseProps,
    isCompletedChallengesLoading: boolean
  ) => ({
    isChallengeCompleted,
    isSignedIn,
    signInLoading,
    completedChallengeIds,
    user,
    userRegistrationStatus,
    isCompletedChallengesLoading
  })
);
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateChallengeMeta,
      createFlashMessage,
      removeFlashMessage,
      challengeMounted,
      openCompletionModal: () => openModal('completion'),
      submitChallenge,
      navigate,
      isAllowedChallenge,
      changeTourStatus
    },
    dispatch
  );

// Types
interface ShowTextOnlyProps {
  challengeMounted: (arg0: string) => void;
  data: { challengeNode: ChallengeNode; allChallengeNode: AllChallengeNode };
  completedChallengeIds: string[];
  description: string;
  isSignedIn: boolean;
  createFlashMessage: typeof createFlashMessage;
  removeFlashMessage: typeof removeFlashMessage;
  signInLoading: boolean;
  navigate: (location: string) => void;
  user: User;
  isChallengeCompleted: boolean;
  openCompletionModal: () => void;
  submitChallenge: () => void;
  pageContext: {
    challengeMeta: ChallengeMeta;
  };
  t: TFunction;
  updateChallengeMeta: (arg0: ChallengeMeta) => void;
  isAllowedChallenge: (challenge: Challenge) => void;
  changeTourStatus: ChangeTourStatusProps;
  userRegistrationStatus: RegistrationResponseProps;
  isCompletedChallengesLoading: boolean;
}

interface ShowTextOnlyState {
  subtitles: string;
}

// Component
class ShowTextOnly extends Component<ShowTextOnlyProps, ShowTextOnlyState> {
  static displayName: string;
  private _container: HTMLElement | null | undefined;

  constructor(props: ShowTextOnlyProps) {
    super(props);
    this.state = {
      subtitles: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(): void {
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge: { title, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta
    } = this.props;

    updateChallengeMeta({
      ...challengeMeta,
      title,
      challengeType,
      helpCategory
    });
    challengeMounted(challengeMeta.id);
    this._container?.focus();
  }

  componentDidUpdate(prevProps: ShowTextOnlyProps): void {
    const {
      data: {
        challengeNode: {
          challenge: { title: prevTitle }
        }
      }
    } = prevProps;
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge,
          challenge: { title: currentTitle, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta,
      isSignedIn,
      isCompletedChallengesLoading
    } = this.props;
    if (prevTitle !== currentTitle) {
      updateChallengeMeta({
        ...challengeMeta,
        title: currentTitle,
        challengeType,
        helpCategory
      });
      challengeMounted(challengeMeta.id);

      if (!isCompletedChallengesLoading) {
        isAllowedChallenge(challenge);
        if (!isSignedIn) {
          navigate(withPrefix('/'));
        }
      }
    }
  }

  componentWillUnmount(): void {
    const { removeFlashMessage } = this.props;

    const slug = this.props.data.challengeNode.challenge.fields.slug;
    if (window.location.pathname !== slug) {
      removeFlashMessage();
    }
  }

  handleSubmit(openCompletionModal: () => void, submitChallenge: () => void) {
    submitChallenge();
    openCompletionModal();
  }

  render() {
    const {
      data: {
        challengeNode: {
          challenge: {
            fields: { blockName },
            title,
            description,
            superBlock,
            certification,
            block,
            phase,
            translationPending
          }
        }
      },
      openCompletionModal,
      submitChallenge,
      pageContext: {
        challengeMeta: { nextChallengePath, prevChallengePath }
      },
      t,
      removeFlashMessage,
      isChallengeCompleted
    } = this.props;

    const blockNameTitle = `${t(
      `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
    )} - ${title}`;

    return (
      <Hotkeys
        executeChallenge={() => {
          removeFlashMessage();

          this.handleSubmit(openCompletionModal, submitChallenge);
        }}
        innerRef={(c: HTMLElement | null) => (this._container = c)}
        nextChallengePath={nextChallengePath}
        prevChallengePath={prevChallengePath}
      >
        <LearnLayout>
          <Helmet
            title={`${blockNameTitle} | ${t('learn.learn')} | DEVStart`}
          />
          <Grid className='navigation-container-video'>
            <BreadCrumb
              block={block}
              superBlock={superBlock}
              phase={phase}
              video={true}
              challengeTitle={title}
            />
          </Grid>
          <Grid className='container-video'>
            <ChallengeTitle
              isCompleted={isChallengeCompleted}
              isCentered
              translationPending={translationPending}
            >
              {title}
            </ChallengeTitle>
            <ChallengeDescription description={description} />
            <div className='video-quiz-options'>
              {nextChallengePath ? (
                <Button
                  buttonType={ButtonTypes.Primary}
                  id='big-button'
                  onClick={() => {
                    removeFlashMessage();

                    this.handleSubmit(openCompletionModal, submitChallenge);
                  }}
                >
                  {t('buttons.go-to-next')}
                </Button>
              ) : null}
            </div>
            <CompletionModal
              block={block}
              blockName={blockName}
              certification={certification}
              superBlock={superBlock}
            />
            <ChallengeNotAllowedModal />
          </Grid>
        </LearnLayout>
      </Hotkeys>
    );
  }
}

ShowTextOnly.displayName = 'ShowTextOnly';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ShowTextOnly));

export const query = graphql`
  query TextOnlyChallenge($slug: String!) {
    challengeNode(challenge: { fields: { slug: { eq: $slug } } }) {
      challenge {
        title
        description
        phase
        challengeType
        helpCategory
        superBlock
        certification
        superOrder
        phaseOrder
        order
        challengeOrder
        block
        fields {
          blockName
          slug
        }
        translationPending
      }
    }
  }
`;
